<template>
  <div class="main">
    <div class="main_box" v-loading="loading">
      <div class="main_title">
        <span>{{projectData.projectName}}</span>
      </div>
      <div class="flex_center main_label">
        <div class="flex_center main_label_l">
          <div class="main_label_item">
            <span>{{projectData.state}}</span>
          </div>
          <div class="main_label_item">
            <span>{{projectData.projectAddress}}</span>
          </div>
        </div>
        <div class="main_label_r">
<!--          <span> 【阅读次数：21 】</span>-->
          <span>发布时间：{{projectData.createTime | timeFilter()}}</span>
        </div>
      </div>
      <div class="main_tabs">
        <el-tabs type="border-card" v-model="tabsItem" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.name" v-for="(item, index) in tabsList" :key="item.value">
            <div class="main_tabs_box" v-for="(items, indexs) in filterProjectData" :key="indexs">
              <div v-html="items.noticeContent"></div>
            </div>
            <template v-if="!filterProjectData.length">
              <el-empty description="暂无数据"></el-empty>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="flex_row_center main_management">
        <el-button v-if="projectData.erpTbProjectId === null" :disabled="isDisabled" @click="onManagement">加入管理</el-button>
        <el-button @click="onManagement" v-else>进入管理</el-button>
      </div>
      <div class="flex main_collect">
        <!--el-icon-star-on-->
        <el-button size="mini" icon="el-icon-star-off" @click="getCommonCollectionAddOrDel">收藏</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { urlErpCommonResDetail2, urlCommonCollectionAddOrDel, urlErpZbProjectDetailByUUID, urlErpZbProjectNoticeDetailWithPrj, urlErpZbProjectListPage } from '@/api/recruit'
  export default {
    name: "projectBiddingDetails",
    data() {
      return {
        projectData: {},
        projectList: [],
        tabsList: [
          { name: '招标公告', value: 0 },
          { name: '变更公告', value: 1 },
          { name: '资格预审', value: 2 },
          { name: '开标结果', value: 3 },
          { name: '澄清答疑', value: 4 },
          { name: '流标公告', value: 5 },
          { name: '中标公示', value: 6 },
          { name: '履约信息', value: 7 }
        ],
        tabsItem: '招标公告',
        loading: true
      }
    },
    filters: {
      timeFilter(time) {
        if (!time) return "-"
        let pulishTime = time.split(' ')[0]
        return pulishTime
      },
    },
    computed: {
      filterProjectData() {
        return this.projectList.filter(item => (item.channelName || item.state) === this.tabsItem);
      }
    },
    mounted() {
      const { tenderingCode, tenderingName } = this.$route.query;
      this.tenderingCode = tenderingCode;
      this.tabsItem = tenderingName ? tenderingName : '招标公告';
      // this.getErpZbProjectDetailByUUID()
      this.getErpZbProjectNoticeDetailWithPrj()
    },
    methods: {
      onManagement() {
        const { protocol, host } = window.location;
        const domain = `${protocol}//${host}`;
        if (this.projectData.erpTbProjectId === null && this.projectData.zbdateStatus !== '已开标') {
          //  加入管理的地址
          window.open(`${domain}/bxbh/#/bidder/allProjects?tenderingCode=${this.tenderingCode}`, "_blank");
          // window.open(`http://localhost:8192/#/bidder/allProjects?tenderingCode=${this.tenderingCode}`, '_blank');
        } else if (this.projectData.erpTbProjectId !== null) {
          //  进入管理的地址
          window.open(`${domain}/bxbh/#/bidder/manageProjects`, "_blank");
          // window.open(`http://localhost:8192/#/bidder/manageProjects`, '_blank');
        }
      },
      async getErpZbProjectDetailByUUID() {
        const param =`uuid=${this.tenderingCode}`;
        const res = await urlErpZbProjectDetailByUUID(param);
        console.log(res)
      },
      async getErpZbProjectNoticeDetailWithPrj() {
        this.loading = true;
        const param =`projectUuid=${this.tenderingCode}`;
        const res = await urlErpZbProjectNoticeDetailWithPrj(param);
        this.projectData = res.data;
        if (!res.data.listNotice) {
          this.projectList = [res.data];
        } else {
          this.projectList = res.data.listNotice;
        }
        this.loading = false;
      },
      async getCommonCollectionAddOrDel() {
        const param = {
          bid: "2e696d6948af40707d88fec100316ea0",
          type: "publicResources"
        };
        const res = await urlCommonCollectionAddOrDel(param);
        console.log(res)
      },
      toLink(e) {
        window.open(e.originalUrl, "_blank");
      },
      handleClick(tab) {
        if (this.tabsItem === tab.label) return;
        this.tabsItem = tab.label;
      }
    }
  }
</script>

<style scoped lang="less">
  /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #4E4E4E;
  }
  /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #1890FF;
  }
  .main {
    text-align: left;
    .main_box {
      .main_title {
        font-size: 20px;
        font-weight: bold;
        color: #212121;
        padding-bottom: 12px;
      }
      .main_label {
        justify-content: space-between;
        padding-bottom: 20px;
        .main_label_l {
          .main_label_item {
            height: 28px;
            padding: 0 6px;
            margin-right: 12px;
            border-radius: 4px;
            background-color: #F6F7FB;
            font-size: 16px;
            color: #4E4E4E;
            line-height: 28px;
          }
        }
        .main_label_r {
          font-size: 14px;
          color: #4E4E4E;
        }
      }
      .main_tabs {
        padding-bottom: 30px;
        .main_tabs_box {
          padding: 20px;
        }
      }
      .main_management {
        padding-bottom: 30px;
      }
      .main_collect {
        justify-content: flex-end;
        padding-bottom: 30px;
      }
    }
  }
</style>